<template>
  <div class="container">
    <p class="fs-14" style="margin-top: 60px;">安全评价也称为风险评价或危险评价，它是以实现安全为目的，应用安全系统工程原理和方法，辨识与分析工程、系统、生产经营活动中的危险、有害因素，预测发生事故或造成职业危害的可能性及其严重程度，提出科学、合理、可行的安全对策措施建议，做出评价结论的活动。</p>
    <p class="fs-14" style="margin-top: 30px;">针对项目的实施阶段，安胜达为客户提供安全生产条件和设施综合分析/安全预评价、安全设施设计、安全验收评价、安全设计诊断、安全现状评价等各类安全评价及其他安全相关服务</p>
    <div style="border: 1px solid #CCCCCC;padding: 60px;margin: 30px 0;">
      <img width="100%" src="@/assets/img/aqpj-bg.png" alt="">
    </div>
  </div>
</template>

<script>
export default {
  name: 'AQPJ',
  components: {},
  props: {},
  data () {
    return {}
  },
  filter: {},
  computed: {},
  watch: {},
  methods: {},
  beforeCreate () {
  },
  created () {

  },
  beforeMount () {
  },
  mounted () {

  },
  beforeUpdate () {
  },
  updated () {
  },
  beforeDestroy () {
  },
  destroyed () {
  }
}
</script>

<style lang="scss" scoped>

</style>
